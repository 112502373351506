var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fc25d6b40c5b6c1609e2882e048c4f629fa5819a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/unity-mkt-services-prd-ui-static/marketplace/fc25d6b40c5b6c1609e2882e048c4f629fa5819a";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  // Have to use NEXT_PUBLIC since regular environment variables aren't accessible on the client side
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Sample rate for volume of all transactions sent to Sentry, should be tweaked per environment
  tracesSampleRate: parseFloat(
    process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE
  ),
  // Sample rate for errors sent to Sentry
  // Its better to send all errors so nothing is missed, and configure a rate limit for the project which only drops events of high volume
  sampleRate: 1,
  // browserTracingIntegration is enabled by default by sentry/nextjs, but if we want to pass any custom config we can do it here
  // only needed in the client config
  integrations: [Sentry.browserTracingIntegration({ enableInp: true })],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

if (process.env.NEXT_PUBLIC_EPHEMERAL_PR_NUMBER) {
  Sentry.setTag('ephemeral_pr', process.env.NEXT_PUBLIC_EPHEMERAL_PR_NUMBER);
}
